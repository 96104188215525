import { type ReactNode, memo, type ComponentType, useEffect } from 'react'

import { useAppSelector, type ReduxState } from 'state/store'
import { storage } from 'utils/storage'

interface Props {
  children: ReactNode
}

function selector(state: ReduxState): boolean {
  return state.app.isDarkMode
}

function _DarkModeToggleWrapper({ children }: Props): ReactNode {
  const isDarkMode = useAppSelector<boolean>(selector)

  useEffect(() => {
    if (isDarkMode) {
      storage.setItem('isDarkMode', 'true')

      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')

      storage.setItem('isDarkMode', 'false')
    }
  }, [isDarkMode])

  return children
}

export const DarkModeToggleWrapper: ComponentType<Props> = memo<Props>(
  _DarkModeToggleWrapper
)
