import type { Flags } from 'const/iso2'

import type {
  BaseFields,
  SelectField,
  InputTelField,
  InputTextField,
  InputEmailField,
  InputPasswordField,
  InputCheckboxField,
  // FetchSuccessPayload,
} from 'types/fields'
import type { IntlMessageId } from 'const/intl'

export type FormName = 'uiKit'

export const formName: FormName = 'uiKit'

export type EmailField = InputEmailField<FormName, 'email'>
export type PhoneField = InputTelField<FormName, 'phone'>
export type PasswordField = InputPasswordField<FormName, 'password'>
export type ConfirmPasswordField = InputPasswordField<
  FormName,
  'confirmPassword'
>
export type FirstNameField = InputTextField<FormName, 'firstName'>
export type LastNameField = InputTextField<FormName, 'lastName'>
export type CountryField = SelectField<Flags | '', FormName, 'country'>
export type AgreeField = InputCheckboxField<FormName, 'agree'>
export type SubjectField = SelectField<
  IntlMessageId | undefined,
  FormName,
  'subject'
>
export type MessageField = InputTextField<FormName, 'message'>

type UiKitFormState = {
  __brand: 'UiKitForm'
  formName: FormName
  email: EmailField
  phone: PhoneField
  password: PasswordField
  confirmPassword: ConfirmPasswordField
  firstName: FirstNameField
  lastName: LastNameField
  country: CountryField
  subject: SubjectField
  message: MessageField
  agree: AgreeField
}

export type UiKitForm = BaseFields & UiKitFormState

export const initialFields: UiKitForm = {
  __brand: 'UiKitForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  // Fields
  email: {
    __brand: 'InputEmailField',
    path: [formName, 'email'],
    type: 'email',
    isTouched: false,
    valid: false,
    value: '',
  },
  firstName: {
    __brand: 'InputTextField',
    path: [formName, 'firstName'],
    type: 'text',
    isTouched: false,
    valid: false,
    value: '',
  },
  lastName: {
    __brand: 'InputTextField',
    path: [formName, 'lastName'],
    type: 'text',
    isTouched: false,
    valid: false,
    value: '',
  },
  country: {
    __brand: 'SelectField',
    path: [formName, 'country'],
    isTouched: false,
    valid: false,
    value: '',
  },
  phone: {
    __brand: 'InputTelField',
    path: [formName, 'phone'],
    type: 'tel',
    isTouched: false,
    valid: false,
    value: '',
  },
  password: {
    __brand: 'InputPasswordField',
    path: [formName, 'password'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
  confirmPassword: {
    __brand: 'InputPasswordField',
    path: [formName, 'confirmPassword'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
  subject: {
    __brand: 'SelectField',
    path: [formName, 'subject'],
    isTouched: false,
    valid: false,
    value: undefined,
  },
  message: {
    __brand: 'InputTextField',
    path: [formName, 'message'],
    type: 'text',
    isTouched: false,
    valid: false,
    value: '',
  },
  agree: {
    __brand: 'InputCheckboxField',
    path: [formName, 'agree'],
    isTouched: false,
    checked: false,
    valid: false,
  },
}

export type UiKitFieldNames = keyof UiKitFormState

// export const initialFieldKeys: Array<UiKitFieldNames> = [
//   'email',
//   'phone',
//   'password',
//   'confirmPassword',
//   'subject',
//   'firstName',
//   'lastName',
//   'country',
//   'agree',
// ]

// export function fetchSuccess(
//   draft: UiKitForm,
//   { success, intlSuccess }: FetchSuccessPayload
// ): void {
//   Object.assign<UiKitForm, Partial<UiKitForm>>(draft, {
//     isFetching: false,
//     errors: [],
//     intlErrors: [],
//     success: success ?? '',
//     intlSuccess: intlSuccess ?? '',
//   })
// }

// export function reset(draft: UiKitForm): void {
//   Object.assign<UiKitForm, UiKitForm>(draft, initialFields)
// }
