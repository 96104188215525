import type {
  BaseFields,
  FetchSuccessPayload,
  InputPasswordField,
} from 'types/fields'

export type FormName = 'profilePassword'

export const formName: FormName = 'profilePassword'

export type PasswordField = InputPasswordField<FormName, 'password'>
export type NewPasswordField = InputPasswordField<FormName, 'newPassword'>
export type ConfirmNewPasswordField = InputPasswordField<
  FormName,
  'confirmNewPassword'
>

type ProfilePasswordFormState = {
  __brand: 'ProfilePasswordForm'
  formName: FormName
  password: PasswordField
  newPassword: NewPasswordField
  confirmNewPassword: ConfirmNewPasswordField
}

export type ProfilePasswordForm = BaseFields & ProfilePasswordFormState

export const initialFields: ProfilePasswordForm = {
  __brand: 'ProfilePasswordForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  // Fields
  password: {
    __brand: 'InputPasswordField',
    path: [formName, 'password'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
  newPassword: {
    __brand: 'InputPasswordField',
    path: [formName, 'newPassword'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
  confirmNewPassword: {
    __brand: 'InputPasswordField',
    path: [formName, 'confirmNewPassword'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
}

export type ProfilePasswordFieldNames = keyof ProfilePasswordFormState

// export const initialFieldKeys: Array<ProfilePasswordFieldNames> = [
//   'password',
//   'newPassword',
//   'confirmNewPassword',
// ]

export function fetchSuccess(
  draft: ProfilePasswordForm,
  { success, intlSuccess }: FetchSuccessPayload
): void {
  Object.assign<ProfilePasswordForm, Partial<ProfilePasswordForm>>(draft, {
    isFetching: false,
    errors: [],
    intlErrors: [],
    success: success ?? '',
    intlSuccess: intlSuccess ?? '',
  })
}

export function reset(draft: ProfilePasswordForm): void {
  Object.assign<ProfilePasswordForm, ProfilePasswordForm>(draft, initialFields)
}
