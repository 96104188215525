import { enableMapSet } from 'immer'

import { reducer as intl, type IntlState } from 'state/reducers/intl'
import { reducer as app, type ApplicationState } from 'state/reducers/app'
import { reducer as profile, type ProfileState } from 'state/reducers/profile'
import { reducer as forms, type FormsState } from 'state/reducers/forms'
import {
  reducer as adminDashboard,
  type AdminDashboardState,
} from 'state/reducers/admin-dashboard'
import {
  reducer as superAdminDashboard,
  type SuperAdminDashboardState,
} from 'state/reducers/superadmin-dashboard'

import {
  reducer as adminUser,
  type AdminUserState,
} from 'state/reducers/admin-user'
import {
  reducer as superAdminAdmin,
  type SuperAdminAdminState,
} from 'state/reducers/superadmin-admin'

enableMapSet()

export interface ReduxState {
  intl: IntlState
  app: ApplicationState
  profile: ProfileState
  forms: FormsState
  adminDashboard: AdminDashboardState
  superAdminDashboard: SuperAdminDashboardState
  adminUser: AdminUserState
  superAdminAdmin: SuperAdminAdminState
}

export const reducer = {
  app,
  intl,
  forms,
  profile,
  adminUser,
  adminDashboard,
  superAdminDashboard,
  superAdminAdmin,
}
