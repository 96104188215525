import type {
  BaseFields,
  FetchSuccessPayload,
  InputPasswordField,
} from 'types/fields'

export type FormName = 'reset'

export const formName: FormName = 'reset'

export type PasswordField = InputPasswordField<FormName, 'password'>
export type ConfirmPasswordField = InputPasswordField<
  FormName,
  'confirmPassword'
>

type ResetFormState = {
  __brand: 'ResetForm'
  formName: FormName
  password: PasswordField
  confirmPassword: ConfirmPasswordField
}

export type ResetForm = BaseFields & ResetFormState

export const initialFields: ResetForm = {
  __brand: 'ResetForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  // Fields
  password: {
    __brand: 'InputPasswordField',
    path: [formName, 'password'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
  confirmPassword: {
    __brand: 'InputPasswordField',
    path: [formName, 'confirmPassword'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
}

export type ResetFieldNames = keyof ResetFormState

// export const initialFieldKeys: Array<ResetFieldNames> = [
//   'password',
//   'confirmPassword',
// ]

export function fetchSuccess(
  draft: ResetForm,
  { success, intlSuccess }: FetchSuccessPayload
): void {
  Object.assign<ResetForm, Partial<ResetForm>>(draft, {
    isFetching: false,
    errors: [],
    intlErrors: [],
    success: success ?? '',
    intlSuccess: intlSuccess ?? '',
  })
}

export function reset(draft: ResetForm): void {
  Object.assign<ResetForm, ResetForm>(draft, initialFields)
}
