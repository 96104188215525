import { createSlice } from '@reduxjs/toolkit'

import type { User } from 'types/network'

export interface SuperAdminAdminState {
  isFetching: boolean
  user: User | null
}

const initialState: Readonly<SuperAdminAdminState> = {
  isFetching: false,
  user: null,
}

const appSlice = createSlice({
  name: 'admin-user',
  initialState,
  reducers: {
    fetchRequest(state): void {
      state.isFetching = true
    },
    fetchSuccess(
      state,
      action: {
        payload: { user: User }
      }
    ): void {
      state.isFetching = false
      state.user = action.payload.user
    },
    fetchFailure(state): void {
      state.isFetching = false
      state.user = null
    },
    reset(state) {
      Object.assign<SuperAdminAdminState, SuperAdminAdminState>(
        state,
        initialState
      )
    },
  },
})

export const { fetchRequest, fetchSuccess, fetchFailure, reset } =
  appSlice.actions

export const reducer = appSlice.reducer
