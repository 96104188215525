import { createSlice } from '@reduxjs/toolkit'
import { navigate } from 'gatsby'

import { GATSBY_DEFAULT_LOCALE } from 'src/env'

import type { Locale } from 'const/intl'

import { intlManager } from 'utils/locale'

export interface IntlState {
  locale: Locale
  isInitialized: boolean
}

const initialState: Readonly<IntlState> = {
  locale: GATSBY_DEFAULT_LOCALE,
  isInitialized: false,
}

interface SetLocalePayload {
  locale: Locale
  link?: string | undefined
}

const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    setLocale(intlState, action: { payload: SetLocalePayload }): void {
      if (
        intlState.isInitialized &&
        intlState.locale === action.payload.locale
      ) {
        return
      }

      if (!intlState.isInitialized) {
        intlState.isInitialized = true
      }

      intlState.locale = action.payload.locale

      intlManager
        .switch({ locale: action.payload.locale })
        .then(() => {
          if (typeof action.payload.link !== 'undefined') {
            navigate(action.payload.link)
          }

          return
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
})

export const { setLocale } = intlSlice.actions

export const reducer = intlSlice.reducer
