import type { Flags } from 'const/iso2'

import type { LoginPayload, UserRoles } from 'types/network'

import type {
  BaseFields,
  SelectField,
  InputTelField,
  InputTextField,
  // InputEmailField,
  InputNumberField,
  FetchSuccessPayload,
  // FetchSuccessPayload,
} from 'types/fields'

export type FormName = 'profilePersonal'

export const formName: FormName = 'profilePersonal'

export type PhoneField = InputTelField<FormName, 'phone'>
// export type EmailField = InputEmailField<FormName, 'email'>
export type FirstNameField = InputTextField<FormName, 'firstName'>
export type LastNameField = InputTextField<FormName, 'lastName'>
export type BirthdayField = InputNumberField<FormName, 'birthday'>
export type CountryField = SelectField<Flags | '', FormName, 'country'>

type ProfilePersonalFormState = {
  __brand: 'ProfilePersonalForm'
  formName: FormName
  role: UserRoles
  phone: PhoneField
  // email: EmailField
  country: CountryField
  firstName: FirstNameField
  lastName: LastNameField
  birthday: BirthdayField
}

export type ProfilePersonalForm = BaseFields & ProfilePersonalFormState

export const initialFields: ProfilePersonalForm = {
  __brand: 'ProfilePersonalForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  role: 'anonymous',
  // Fields
  // email: {
  //   __brand: 'InputEmailField',
  //   path: [formName, 'email'],
  //   type: 'email',
  //   isTouched: false,
  //   valid: false,
  //   value: '',
  // },
  firstName: {
    __brand: 'InputTextField',
    path: [formName, 'firstName'],
    type: 'text',
    isTouched: false,
    value: '',
    valid: false,
  },
  lastName: {
    __brand: 'InputTextField',
    path: [formName, 'lastName'],
    type: 'text',
    isTouched: false,
    value: '',
    valid: false,
  },
  birthday: {
    __brand: 'InputNumberField',
    path: [formName, 'birthday'],
    type: 'number',
    isTouched: false,
    value: -Infinity,
    valid: false,
  },
  phone: {
    __brand: 'InputTelField',
    path: [formName, 'phone'],
    type: 'tel',
    isTouched: false,
    valid: false,
    value: '',
  },
  country: {
    __brand: 'SelectField',
    path: [formName, 'country'],
    isTouched: false,
    valid: false,
    value: '',
  },
}

export type ProfilePersonalFieldNames = keyof ProfilePersonalFormState

// export const initialFieldKeys: Array<ProfilePersonalFieldNames> = [
//   'phone',
//   'email',
//   'country',
//   'firstName',
//   'lastName',
//   'birthday',
// ]

export function fetchSuccess(
  draft: ProfilePersonalForm,
  { success, intlSuccess }: FetchSuccessPayload
): void {
  Object.assign<ProfilePersonalForm, Partial<ProfilePersonalForm>>(draft, {
    isFetching: false,
    errors: [],
    intlErrors: [],
    success: success ?? '',
    intlSuccess: intlSuccess ?? '',
  })
}

export function profileUpdate(
  draft: ProfilePersonalForm,
  { firstName, lastName, phone, birthday, country, role }: LoginPayload
): void {
  draft.role = role
  draft.firstName.value = firstName
  draft.lastName.value = lastName

  if (typeof phone === 'string') {
    draft.phone.value = phone
  }

  if (typeof birthday !== 'undefined') {
    draft.birthday.value = birthday
  }

  if (typeof country !== 'undefined') {
    draft.country.value = country
  }
}

export function logIn(draft: ProfilePersonalForm, payload: LoginPayload): void {
  // draft.email.value = payload.email

  profileUpdate(draft, payload)
}

export function reset(draft: ProfilePersonalForm): void {
  Object.assign<ProfilePersonalForm, ProfilePersonalForm>(draft, initialFields)
}
