import type {
  BaseFields,
  FetchSuccessPayload,
  InputPasswordField,
} from 'types/fields'

export type FormName = 'adminUserPassword'

export const formName: FormName = 'adminUserPassword'

export type NewPasswordField = InputPasswordField<FormName, 'newPassword'>
export type ConfirmNewPasswordField = InputPasswordField<
  FormName,
  'confirmNewPassword'
>

type AdminUserPasswordFormState = {
  __brand: 'AdminUserPasswordForm'
  formName: FormName
  newPassword: NewPasswordField
  confirmNewPassword: ConfirmNewPasswordField
}

export type AdminUserPasswordForm = BaseFields & AdminUserPasswordFormState

export const initialFields: AdminUserPasswordForm = {
  __brand: 'AdminUserPasswordForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  // Fields
  newPassword: {
    __brand: 'InputPasswordField',
    path: [formName, 'newPassword'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
  confirmNewPassword: {
    __brand: 'InputPasswordField',
    path: [formName, 'confirmNewPassword'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
}

export type AdminUserPasswordFieldNames = keyof AdminUserPasswordFormState

// export const initialFieldKeys: Array<AdminUserPasswordFieldNames> = [
//   'newPassword',
//   'confirmNewPassword',
// ]

export function fetchSuccess(
  draft: AdminUserPasswordForm,
  { success, intlSuccess }: FetchSuccessPayload
): void {
  Object.assign<AdminUserPasswordForm, Partial<AdminUserPasswordForm>>(draft, {
    isFetching: false,
    errors: [],
    intlErrors: [],
    success: success ?? '',
    intlSuccess: intlSuccess ?? '',
  })
}

export function reset(draft: AdminUserPasswordForm): void {
  Object.assign<AdminUserPasswordForm, AdminUserPasswordForm>(
    draft,
    initialFields
  )
}
