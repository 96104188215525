import { createAction, createReducer } from '@reduxjs/toolkit'

import { mergeDeep } from 'utils/object'

import type {
  FormFields,
  SetInPayload,
  ValidatePayload,
  ChangeInputPayload,
  FetchFailurePayload,
  FetchSuccessPayload,
  FetchRequestPayload,
  FetchCompletePayload,
  ChangeCheckboxPayload,
  ChangeRadioGroupPayload,
  // ChangeInputFilePayload,
} from 'types/fields'

import {
  // reset as resetUiKit,
  initialFields as initialFieldsUiKit,
} from 'state/forms/ui-kit'

import {
  reset as resetForgot,
  fetchSuccess as fetchSuccessForgot,
  initialFields as initialFieldsForgot,
} from 'state/forms/forgot'
import {
  reset as resetLogin,
  fetchSuccess as fetchSuccessLogIn,
  initialFields as initialFieldsLogIn,
} from 'state/forms/login'
import {
  reset as resetReset,
  fetchSuccess as fetchSuccessReset,
  initialFields as initialFieldsReset,
} from 'state/forms/reset'
import {
  reset as resetSignup,
  fetchSuccess as fetchSuccessSignup,
  initialFields as initialFieldsSignup,
} from 'state/forms/signup'
import {
  reset as resetContact,
  fetchSuccess as fetchSuccessContact,
  initialFields as initialFieldsContact,
} from 'state/forms/contact'

import {
  reset as resetProfilePassword,
  fetchSuccess as fetchSuccessProfilePassword,
  initialFields as initialFieldsProfilePassword,
} from 'state/forms/profile-password'
import {
  logIn as logInProfilePersonal,
  reset as resetProfilePersonal,
  fetchSuccess as fetchSuccessProfilePersonal,
  initialFields as initialFieldsProfilePersonal,
  profileUpdate as profileUpdateProfilePersonal,
} from 'state/forms/profile-personal'

import {
  reset as resetAdminUserPassword,
  fetchSuccess as fetchSuccessAdminUserPassword,
  initialFields as initialFieldsAdminUserPassword,
} from 'state/forms/admin-user-password'
import {
  reset as resetAdminUserPersonal,
  fetchSuccess as fetchSuccessAdminUserPersonal,
  initialFields as initialFieldsAdminUserPersonal,
  adminUserPersonalUpdate,
} from 'state/forms/admin-user-personal'

import { initialFields as initialFieldsAdminDashboardSearch } from 'state/forms/admin-dashboard-search'
import { initialFields as initialFieldsSuperAdminDashboardSearch } from 'state/forms/superadmin-dashboard-search'

import type {
  LoginPayload,
  ProfileResponse,
  AdminUserProfile,
} from 'types/network'

export const logIn = createAction<LoginPayload, 'logIn'>('logIn')
type LogInAction = ReturnType<typeof logIn>

export const logOut = createAction<undefined, 'logOut'>('logOut')
type LogOutAction = ReturnType<typeof logOut>

export const updateProfile = createAction<ProfileResponse, 'updateProfile'>(
  'updateProfile'
)
export const updateAdminUserPersonal = createAction<
  AdminUserProfile,
  'updateAdminUser'
>('updateAdminUser')
type UpdateProfileAction = ReturnType<typeof updateProfile>
type UpdateAdminUserPersonalAction = ReturnType<typeof updateAdminUserPersonal>

export const fetchRequest = createAction<FetchRequestPayload, 'fetchRequest'>(
  'fetchRequest'
)
type FetchRequestAction = ReturnType<typeof fetchRequest>

export const fetchSuccess = createAction<FetchSuccessPayload, 'fetchSuccess'>(
  'fetchSuccess'
)
type FetchSuccessAction = ReturnType<typeof fetchSuccess>

export const fetchFailure = createAction<FetchFailurePayload, 'fetchFailure'>(
  'fetchFailure'
)
type FetchFailureAction = ReturnType<typeof fetchFailure>

export const fetchComplete = createAction<
  FetchCompletePayload,
  'fetchComplete'
>('fetchComplete')
type FetchCompleteAction = ReturnType<typeof fetchComplete>

export const setIn = createAction<SetInPayload, 'setIn'>('setIn')
type SetInAction = ReturnType<typeof setIn>

export const changeInput = createAction<ChangeInputPayload, 'changeInput'>(
  'changeInput'
)
type ChangeInputAction = ReturnType<typeof changeInput>

export const changeCheckbox = createAction<
  ChangeCheckboxPayload,
  'changeCheckbox'
>('changeCheckbox')
type ChangeCheckboxAction = ReturnType<typeof changeCheckbox>

// export const changeFile = createAction<ChangeInputFilePayload, 'changeFile'>(
//   'changeFile'
// )
// type ChangeFileAction = ReturnType<typeof changeFile>

export const validateInput = createAction<ValidatePayload, 'validateInput'>(
  'validateInput'
)
type ValidateInputAction = ReturnType<typeof validateInput>

export const changeRadioGroup = createAction<
  ChangeRadioGroupPayload,
  'changeRadioGroup'
>('changeRadioGroup')
type ChangeRadioGroupAction = ReturnType<typeof changeRadioGroup>

const initialState = {
  uiKit: initialFieldsUiKit,
  forgot: initialFieldsForgot,
  signUp: initialFieldsSignup,
  logIn: initialFieldsLogIn,
  reset: initialFieldsReset,
  contact: initialFieldsContact,
  profilePassword: initialFieldsProfilePassword,
  profilePersonal: initialFieldsProfilePersonal,
  adminUserPassword: initialFieldsAdminUserPassword,
  adminUserPersonal: initialFieldsAdminUserPersonal,
  adminDashboardSearch: initialFieldsAdminDashboardSearch,
  superAdminDashboardSearch: initialFieldsSuperAdminDashboardSearch,
}

export type FormsState = typeof initialState

export type FormNames = keyof typeof initialState

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setIn, (draft, action: SetInAction): void => {
      const { path: _path, ...rest } = action.payload

      const formName = action.payload.path[0]

      const field = draft[formName]

      mergeDeep(field, rest)
    })
    .addCase(logIn, (draft, action: LogInAction): void => {
      logInProfilePersonal(draft.profilePersonal, action.payload)

      resetLogin(draft.logIn)
    })

    .addCase(logOut, (draft, _action: LogOutAction): void => {
      resetLogin(draft.logIn)
      resetForgot(draft.forgot)
      resetSignup(draft.signUp)
      resetReset(draft.reset)
      resetContact(draft.contact)
      resetProfilePassword(draft.profilePassword)
      resetProfilePersonal(draft.profilePersonal)
      resetAdminUserPassword(draft.adminUserPassword)
      resetAdminUserPersonal(draft.adminUserPersonal)
    })
    .addCase(
      changeInput,
      (draft: FormsState, action: ChangeInputAction): void => {
        const { path: _path, ...rest } = action.payload

        const formName = action.payload.path[0]
        const fieldName = action.payload.path[1]

        const form = draft[formName]

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (fieldName) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const field = form[fieldName]

          mergeDeep(field, rest)
        }
      }
    )

    .addCase(fetchRequest, (draft, action: FetchRequestAction): void => {
      const formName = action.payload.path[0]

      const reducer = draft[formName]

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultFetchRequest(reducer, action.payload)
    })
    .addCase(fetchSuccess, (draft, action: FetchSuccessAction): void => {
      switch (action.payload.path[0]) {
        case 'logIn': {
          fetchSuccessLogIn(draft.logIn, {
            path: ['logIn'],
          })

          break
        }

        case 'signUp': {
          fetchSuccessSignup(draft.signUp, {
            path: ['signUp'],
          })

          break
        }

        case 'reset': {
          fetchSuccessReset(draft.reset, {
            path: ['reset'],
          })

          break
        }

        case 'forgot': {
          fetchSuccessForgot(draft.forgot, {
            path: ['forgot'],
          })

          break
        }

        case 'contact': {
          fetchSuccessContact(draft.contact, {
            path: ['contact'],
          })

          break
        }

        case 'profilePassword': {
          fetchSuccessProfilePassword(draft.profilePassword, {
            path: ['profilePassword'],
          })

          break
        }

        case 'profilePersonal': {
          fetchSuccessProfilePersonal(draft.profilePersonal, {
            path: ['profilePersonal'],
          })

          break
        }

        case 'adminUserPassword': {
          fetchSuccessAdminUserPassword(draft.adminUserPassword, {
            path: ['adminUserPassword'],
          })

          break
        }

        case 'adminUserPersonal': {
          fetchSuccessAdminUserPersonal(draft.adminUserPersonal, {
            path: ['adminUserPersonal'],
          })

          break
        }

        default: {
          break
        }
      }
    })

    .addCase(fetchFailure, (draft, action: FetchFailureAction): void => {
      const formName = action.payload.path[0]

      const reducer = draft[formName]

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultFetchFailure(reducer, action.payload)
    })
    .addCase(fetchComplete, (draft, action: FetchCompleteAction): void => {
      const { path: _path, ...rest } = action.payload

      const formName = action.payload.path[0]

      const form = draft[formName]

      mergeDeep(form, {
        isFetching: false,
        ...rest,
      })
    })

    .addCase(updateProfile, (draft, action: UpdateProfileAction): void => {
      profileUpdateProfilePersonal(draft.profilePersonal, action.payload)
    })

    .addCase(
      updateAdminUserPersonal,
      (draft, action: UpdateAdminUserPersonalAction): void => {
        adminUserPersonalUpdate(draft.adminUserPersonal, action.payload)
      }
    )

    // .addCase(changeFile, (draft, action: ChangeFileAction) => {
    //   const { path: _path, ...rest } = action.payload

    //   const formName = action.payload.path[0]

    //   const form = draft[formName]

    //   mergeDeep(form, rest)
    // })
    .addCase(changeCheckbox, (draft, action: ChangeCheckboxAction): void => {
      const { path, ...rest } = action.payload

      const formName = path[0]
      const fieldName = path[1]

      if (typeof fieldName === 'undefined') {
        return
      }

      const form = draft[formName]

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const field = form[fieldName]

      mergeDeep(field, rest)
    })
    .addCase(
      changeRadioGroup,
      (draft, action: ChangeRadioGroupAction): void => {
        const { path, ...rest } = action.payload

        const formName = path[0]
        const fieldName = path[1]

        if (typeof fieldName === 'undefined') {
          return
        }

        const form = draft[formName]

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const field = form[fieldName]

        mergeDeep(field, rest)
      }
    )
    .addCase(validateInput, (draft, action: ValidateInputAction): void => {
      const { value, isValid } = action.payload

      const formName = action.payload.path[0]
      const fieldName = action.payload.path[1]

      const form = draft[formName]

      if (typeof fieldName !== 'undefined') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const field = form[fieldName]

        mergeDeep(field, {
          value,
          isTouched: true,
          valid: isValid,
        })
      }
    })
})

function defaultFetchRequest(
  draft: FormFields,
  { path: _path }: FetchRequestPayload
): void {
  draft.isFetching = true

  draft.errors = []
  draft.intlErrors = []

  draft.success = ''
  draft.intlSuccess = ''
}

function defaultFetchFailure(
  draft: FormFields,
  { errors, intlErrors }: FetchFailurePayload
): void {
  draft.isFetching = false

  draft.success = ''
  draft.intlSuccess = ''

  if (Array.isArray(errors)) {
    draft.errors = errors
  }

  if (Array.isArray(intlErrors)) {
    draft.intlErrors = intlErrors
  }
}
