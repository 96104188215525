import type { ServerError } from 'types/network'
import { GATSBY_API_URL, GATSBY_ADMIN_URL, GATSBY_AUTH_URL } from '../env'

export class HttpService {
  protected static get(
    url: RequestInfo | URL,
    headers?: Readonly<Record<string, string>> | undefined
  ): Promise<void> {
    const options: RequestInit = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...headers,
        Accept: 'application/json',
      },
    }

    return window
      .fetch(url, options)
      .then<void>(this.handleGetResponse)
      .catch(this.handleError)
  }

  protected static getJson<T>(
    url: RequestInfo | URL,
    headers?: Readonly<Record<string, string>> | undefined
  ): Promise<T> {
    const options: RequestInit = {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...headers,
        Accept: 'application/json',
      },
    }

    return window
      .fetch(url, options)
      .then<T>(this.jsonParse)
      .catch(this.handleError)
  }

  protected static putJson<T>(
    url: RequestInfo | URL,
    body: BodyInit | null,
    headers?: Readonly<Record<string, string>> | undefined
  ): Promise<T> {
    const options: RequestInit = {
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    }

    return window
      .fetch(url, options)
      .then<T>(this.jsonParse)
      .catch(this.handleError)
  }

  protected static deleteJson<T>(
    url: RequestInfo | URL,
    body: BodyInit | null,
    headers?: Readonly<Record<string, string>> | undefined
  ): Promise<T> {
    const options: RequestInit = {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    }

    return window
      .fetch(url, options)
      .then<T>(this.jsonParse)
      .catch(this.handleError)
  }

  protected static postJson<T>(
    url: RequestInfo | URL,
    body: BodyInit | null,
    headers?: Readonly<Record<string, string>> | undefined
  ): Promise<T> {
    const options: RequestInit = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    }

    return window
      .fetch(url, options)
      .then<T>(this.jsonParse)
      .catch(this.handleError)
  }

  protected static post<T>(
    url: RequestInfo | URL,
    body: BodyInit | null,
    headers?: Readonly<Record<string, string>> | undefined
  ): Promise<T> {
    const options: RequestInit = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...headers,
        Accept: 'application/json',
      },
      body,
    }

    return window
      .fetch(url, options)
      .then<T>(this.jsonParse)
      .catch(this.handleError)
  }

  private static jsonParse<T>(response: Response): Promise<T> {
    if (response.status === 204) {
      // eslint-disable-next-line total-functions/no-unsafe-type-assertion
      return Promise.resolve(response.ok) as unknown as Promise<T>
    }

    const contentType =
      response.headers.get('content-type') ??
      response.headers.get('Content-Type') ??
      ''

    if (contentType.includes('application/json')) {
      const result = response.json()

      if (!response.ok) {
        return result.then(HttpService.handleServerError)
      }

      // eslint-disable-next-line total-functions/no-unsafe-type-assertion
      return result as Promise<T>
    }

    switch (response.status) {
      case 404:
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
        throw new Error(`error.server-${response.status}`)
      default:
        break
    }

    throw response.statusText
  }

  private static handleGetResponse(response: Response): Promise<void> {
    if (response.status !== 200) {
      return HttpService.jsonParse(response)
    }

    return Promise.resolve()
  }

  private static handleServerError(result: unknown): PromiseLike<never> {
    // eslint-disable-next-line total-functions/no-unsafe-type-assertion
    throw new Error((result as ServerError).error)
  }

  private static handleError(err: Error | string): never {
    if (typeof err === 'undefined') {
      throw new Error('error.undefined-error-response')
    }

    if (typeof err === 'string' && err === '') {
      throw new Error('error.empty-string-error-response')
    }

    throw err instanceof Error ? err : new Error(err)
  }
}

export function getAuthEndpoint(route: string | undefined = ''): string {
  return `${GATSBY_AUTH_URL}/${route}`
}

export function getApiEndpoint(route: string | undefined = ''): string {
  return `${GATSBY_API_URL}/${route}`
}

export function getAdminEndpoint(route: string | undefined = ''): string {
  return `${GATSBY_ADMIN_URL}/${route}`
}

export function handleError(err: Error | string): never {
  if (typeof err === 'undefined') {
    throw new Error('error.undefined-error-response')
  }

  if (typeof err === 'string' && err === '') {
    throw new Error('error.empty-string-error-response')
  }

  throw err instanceof Error ? err : new Error(err)
}

export function handleServerError(result: unknown): PromiseLike<never> {
  // eslint-disable-next-line total-functions/no-unsafe-type-assertion
  throw new Error((result as ServerError).error)
}

async function jsonParse<T>(response: Response): Promise<T> {
  if (response.status === 204) {
    // eslint-disable-next-line total-functions/no-unsafe-type-assertion
    return Promise.resolve(response.ok) as unknown as Promise<T>
  }

  const contentType = response.headers.get('content-type') ?? ''

  if (contentType.includes('application/json')) {
    const result = response.json()

    if (!response.ok) {
      return result.then(handleServerError)
    }

    // eslint-disable-next-line total-functions/no-unsafe-type-assertion
    return result as Promise<T>
  }

  switch (response.status) {
    case 404:
    case 500:
    case 501:
    case 502:
    case 503:
    case 504:
      throw new Error(`error.server-${response.status}`)
    default:
      break
  }

  throw response.statusText
}

export async function getJson<T>(
  url: RequestInfo | URL,
  headers?: Readonly<Record<string, string>> | undefined
): Promise<T> {
  const options: RequestInit = {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...headers,
      Accept: 'application/json',
    },
  }

  return window.fetch(url, options).then<T>(jsonParse).catch(handleError)
}

export async function postJson<T>(
  url: RequestInfo | URL,
  body: BodyInit | null,
  headers?: Readonly<Record<string, string>> | undefined
): Promise<T> {
  const options: RequestInit = {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  }

  return window.fetch(url, options).then<T>(jsonParse).catch(handleError)
}
