import { createSlice } from '@reduxjs/toolkit'

import type { User } from 'types/network'

export interface SuperAdminDashboardState {
  isFetching: boolean
  admins: User[]
  currentPage: number
  totalPages: number
  totalUsers: number
}

const initialState: Readonly<SuperAdminDashboardState> = {
  isFetching: false,
  admins: [],
  currentPage: 0,
  totalPages: 0,
  totalUsers: 0,
}

const appSlice = createSlice({
  name: 'superadmin-dashboard',
  initialState,
  reducers: {
    fetchRequest(state): void {
      state.isFetching = true
    },
    fetchSuccess(
      state,
      action: {
        payload: {
          users: User[]
          currentPage: number
          totalPages: number
          totalUsers: number
        }
      }
    ): void {
      state.isFetching = false
      state.admins = action.payload.users
      state.currentPage = action.payload.currentPage
      state.totalPages = action.payload.totalPages
      state.totalUsers = action.payload.totalUsers
    },
    fetchFailure(state): void {
      state.isFetching = false
      state.admins = []
    },
    setCurrentPage(state, action: { payload: number }): void {
      state.currentPage = action.payload
    },
    reset(state) {
      Object.assign<SuperAdminDashboardState, SuperAdminDashboardState>(
        state,
        initialState
      )
    },
  },
})

export const {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  setCurrentPage,
  reset,
} = appSlice.actions

export const reducer = appSlice.reducer
