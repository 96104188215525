import type { Flags } from 'const/iso2'

import type {
  BaseFields,
  SelectField,
  InputTelField,
  InputTextField,
  InputEmailField,
  InputPasswordField,
  InputCheckboxField,
  FetchSuccessPayload,
} from 'types/fields'

export type FormName = 'signUp'

export const formName: FormName = 'signUp'

export type EmailField = InputEmailField<FormName, 'email'>
export type PhoneField = InputTelField<FormName, 'phone'>
export type PasswordField = InputPasswordField<FormName, 'password'>
export type ConfirmPasswordField = InputPasswordField<
  FormName,
  'confirmPassword'
>
export type FirstNameField = InputTextField<FormName, 'firstName'>
export type LastNameField = InputTextField<FormName, 'lastName'>
export type CountryField = SelectField<Flags | '', FormName, 'country'>
export type AgreeField = InputCheckboxField<FormName, 'agree'>

type SignUpFormState = {
  __brand: 'SignUpForm'
  formName: FormName
  email: EmailField
  phone: PhoneField
  password: PasswordField
  confirmPassword: ConfirmPasswordField
  firstName: FirstNameField
  lastName: LastNameField
  country: CountryField
  agree: AgreeField
}

export type SignUpForm = BaseFields & SignUpFormState

export const initialFields: SignUpForm = {
  __brand: 'SignUpForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  // Fields
  email: {
    __brand: 'InputEmailField',
    path: [formName, 'email'],
    type: 'email',
    isTouched: false,
    valid: false,
    value: '',
  },
  firstName: {
    __brand: 'InputTextField',
    path: [formName, 'firstName'],
    type: 'text',
    isTouched: false,
    valid: false,
    value: '',
  },
  lastName: {
    __brand: 'InputTextField',
    path: [formName, 'lastName'],
    type: 'text',
    isTouched: false,
    valid: false,
    value: '',
  },
  country: {
    __brand: 'SelectField',
    path: [formName, 'country'],
    isTouched: false,
    valid: false,
    value: '',
  },
  phone: {
    __brand: 'InputTelField',
    path: [formName, 'phone'],
    type: 'tel',
    isTouched: false,
    valid: false,
    value: '',
  },
  password: {
    __brand: 'InputPasswordField',
    path: [formName, 'password'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
  confirmPassword: {
    __brand: 'InputPasswordField',
    path: [formName, 'confirmPassword'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
  agree: {
    __brand: 'InputCheckboxField',
    path: [formName, 'agree'],
    isTouched: false,
    checked: false,
    valid: false,
  },
}

export type SignUpFieldNames = keyof SignUpFormState

// export const initialFieldKeys: Array<SignUpFieldNames> = [
//   'email',
//   'phone',
//   'password',
//   'confirmPassword',
//   'firstName',
//   'lastName',
//   'country',
//   'agree',
// ]

export function fetchSuccess(
  draft: SignUpForm,
  { success, intlSuccess }: FetchSuccessPayload
): void {
  Object.assign<SignUpForm, Partial<SignUpForm>>(draft, {
    isFetching: false,
    errors: [],
    intlErrors: [],
    success: success ?? '',
    intlSuccess: intlSuccess ?? '',
  })
}

export function reset(draft: SignUpForm): void {
  Object.assign<SignUpForm, SignUpForm>(draft, initialFields)
}
