// should be imported relative!!!
// import { isBrowser } from './is-browser'

import { GATSBY_DEFAULT_LOCALE } from '../env'

// should be imported relative!!!
import {
  type Locale,
  isAvailableLocale,
  type IntlMessageId,
} from '../const/intl'

export function getLocaleFromLocation(path: string): Locale {
  const chunks: Array<string | ''> = path.split('/')

  const cleanedChunks: Array<string> = chunks.filter((chunk) => chunk !== '')

  const locale: string | undefined =
    cleanedChunks.length > 0 ? cleanedChunks[0] : undefined

  return typeof locale === 'string' && isAvailableLocale(locale)
    ? locale
    : GATSBY_DEFAULT_LOCALE
}

type IntlMessages = Readonly<Record<IntlMessageId, string>>
type IntlMessagesMap = Map<Locale, IntlMessages>
type ListenerFunc = (locale: Locale) => void

async function importLocaleData(locale: Locale): Promise<IntlMessages> {
  switch (locale) {
    case 'te': {
      return import('../const/intl/te.json', {
        with: {
          type: 'json',
        },
      }).then((data) => {
        return data.default
      })
    }

    case 'en': {
      return import('../const/intl/en.json', {
        with: {
          type: 'json',
        },
      }).then((data) => {
        return data.default
      })
    }

    case 'ru': {
      return import('../const/intl/ru.json', {
        with: {
          type: 'json',
        },
      }).then((data) => {
        return data.default
      })
    }

    default: {
      const unknown: string = locale

      throw new Error(`Unexpected locale "${unknown}"`)
    }
  }
}

class IntlManager {
  private locale: Locale
  private messages: IntlMessagesMap
  private listeners: Set<ListenerFunc>

  public constructor() {
    this.locale = GATSBY_DEFAULT_LOCALE
    this.messages = new Map()
    this.listeners = new Set()
  }

  public init(locale: Locale): Promise<void> {
    if (!isAvailableLocale(locale)) {
      locale = GATSBY_DEFAULT_LOCALE
    }

    return importLocaleData(locale).then<void>((data: IntlMessages): void => {
      this.locale = locale

      if (!this.messages.has(locale)) {
        this.messages.set(locale, data)
      }

      return
    })
  }

  public subscribe(listener: ListenerFunc): () => void {
    this.listeners.add(listener)

    return () => {
      this.listeners.delete(listener)
    }
  }

  private notify(locale: Locale): void {
    for (const listener of this.listeners) {
      listener(locale)
    }
  }

  public async switch({ locale }: { locale: Locale }): Promise<void> {
    if (this.messages.has(locale)) {
      this.locale = locale

      this.notify(locale)

      return Promise.resolve()
    }

    return await importLocaleData(locale).then<void>((data: IntlMessages) => {
      // console.info('new locale:', locale)

      this.messages.set(locale, data)

      this.locale = locale

      this.notify(locale)

      return
    })
  }

  public async setMessages(locale: Locale): Promise<void> {
    const messages = await import(`../const/intl/${locale}.json`, {
      with: {
        type: 'json',
      },
    })
      .then((data) => {
        return data
      })
      .catch((error) => {
        console.error('setMessages error:', error)
      })

    this.messages.set(locale, messages)
  }

  public setMessagesSync(locale: Locale): void {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, total-functions/no-unsafe-type-assertion
    const messages = require(`../const/intl/${locale}.json`) as Record<
      IntlMessageId,
      string
    >

    this.messages.set(locale, messages)
  }

  public getMessages(locale: Locale, template: string): IntlMessages {
    if (typeof locale !== 'undefined') {
      const messages = this.messages.get(locale)

      if (typeof messages !== 'undefined') {
        return messages
      }
    }

    const messages = this.messages.get(locale)

    if (typeof messages !== 'undefined') {
      return messages
    }

    console.warn('messages not found for locale:', locale, 'template', template)

    // eslint-disable-next-line total-functions/no-unsafe-type-assertion
    return {} as IntlMessages
  }

  public getLocale(): Locale {
    return this.locale
  }
}

export const intlManager = new IntlManager()
