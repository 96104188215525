import type { Flags } from 'const/iso2'

import type { LoginPayload, UserRoles } from 'types/network'

import type {
  BaseFields,
  SelectField,
  InputTelField,
  InputTextField,
  InputEmailField,
  InputNumberField,
  FetchSuccessPayload,
  // FetchSuccessPayload,
} from 'types/fields'

export type FormName = 'adminUserPersonal'

export const formName: FormName = 'adminUserPersonal'

export type PhoneField = InputTelField<FormName, 'phone'>
type EmailField = InputEmailField<FormName, 'email'>
export type FirstNameField = InputTextField<FormName, 'firstName'>
export type LastNameField = InputTextField<FormName, 'lastName'>
export type BirthdayField = InputNumberField<FormName, 'birthday'>
export type CountryField = SelectField<Flags | '', FormName, 'country'>

export type RolesField = SelectField<
  Exclude<UserRoles, 'admin' | 'superadmin'>,
  FormName,
  'role'
>

type AdminUserPersonalFormState = {
  __brand: 'AdminUserPersonalForm'
  formName: FormName
  role: RolesField
  phone: PhoneField
  email: EmailField
  country: CountryField
  firstName: FirstNameField
  lastName: LastNameField
  birthday: BirthdayField
}

export type AdminUserPersonalForm = BaseFields & AdminUserPersonalFormState

export const initialFields: AdminUserPersonalForm = {
  __brand: 'AdminUserPersonalForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  role: {
    __brand: 'SelectField',
    path: [formName, 'role'],
    isTouched: false,
    valid: false,
    value: 'anonymous',
  },
  // Fields
  email: {
    __brand: 'InputEmailField',
    path: [formName, 'email'],
    type: 'email',
    isTouched: false,
    valid: false,
    value: '',
  },
  firstName: {
    __brand: 'InputTextField',
    path: [formName, 'firstName'],
    type: 'text',
    isTouched: false,
    value: '',
    valid: false,
  },
  lastName: {
    __brand: 'InputTextField',
    path: [formName, 'lastName'],
    type: 'text',
    isTouched: false,
    value: '',
    valid: false,
  },
  birthday: {
    __brand: 'InputNumberField',
    path: [formName, 'birthday'],
    type: 'number',
    isTouched: false,
    value: -Infinity,
    valid: false,
  },
  phone: {
    __brand: 'InputTelField',
    path: [formName, 'phone'],
    type: 'tel',
    isTouched: false,
    valid: false,
    value: '',
  },
  country: {
    __brand: 'SelectField',
    path: [formName, 'country'],
    isTouched: false,
    valid: false,
    value: '',
  },
}

export type AdminUserPersonalFieldNames = keyof AdminUserPersonalFormState

// export const initialFieldKeys: Array<AdminUserPersonalFieldNames> = [
//   'phone',
//   'email',
//   'country',
//   'firstName',
//   'lastName',
//   'birthday',
// ]

export function fetchSuccess(
  draft: AdminUserPersonalForm,
  { success, intlSuccess }: FetchSuccessPayload
): void {
  Object.assign<AdminUserPersonalForm, Partial<AdminUserPersonalForm>>(draft, {
    isFetching: false,
    errors: [],
    intlErrors: [],
    success: success ?? '',
    intlSuccess: intlSuccess ?? '',
  })
}

export function adminUserPersonalUpdate(
  draft: AdminUserPersonalForm,
  { firstName, lastName, phone, birthday, country, role, email }: LoginPayload
): void {
  if (role === 'admin' || role === 'superadmin') {
    throw new Error(
      'admin or admin role account should be edited only by superadmin accounts'
    )
  }

  draft.email.value = email

  draft.role.value = role
  draft.firstName.value = firstName
  draft.lastName.value = lastName

  if (typeof phone === 'string') {
    draft.phone.value = phone
  }

  if (typeof birthday !== 'undefined') {
    draft.birthday.value = birthday
  }

  if (typeof country !== 'undefined') {
    draft.country.value = country
  }
}

export function reset(draft: AdminUserPersonalForm): void {
  Object.assign<AdminUserPersonalForm, AdminUserPersonalForm>(
    draft,
    initialFields
  )
}
