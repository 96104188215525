import type {
  BaseFields,
  InputEmailField,
  FetchSuccessPayload,
} from 'types/fields'

export type FormName = 'forgot'

export const formName: FormName = 'forgot'

export type EmailField = InputEmailField<FormName, 'email'>

type ForgotFormState = {
  __brand: 'ForgotForm'
  formName: FormName
  email: EmailField
}

export type ForgotForm = BaseFields & ForgotFormState

export const initialFields: ForgotForm = {
  __brand: 'ForgotForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  // Fields
  email: {
    __brand: 'InputEmailField',
    path: [formName, 'email'],
    type: 'email',
    isTouched: false,
    valid: false,
    value: '',
  },
}

export type ForgotFieldNames = keyof ForgotFormState

// export const initialFieldKeys: Array<ForgotFieldNames> = ['email']

export function fetchSuccess(
  draft: ForgotForm,
  { success, intlSuccess }: FetchSuccessPayload
): void {
  Object.assign<ForgotForm, Partial<ForgotForm>>(draft, {
    isFetching: false,
    errors: [],
    intlErrors: [],
    success: success ?? '',
    intlSuccess: intlSuccess ?? '',
  })
}

export function reset(draft: ForgotForm): void {
  Object.assign<ForgotForm, ForgotForm>(draft, initialFields)
}
