/* eslint-env browser, node */
import '@total-typescript/ts-reset'
import 'whatwg-fetch'
import 'regenerator-runtime/runtime'

import {
  // withPrefix,
  type GatsbyBrowser,
  type PluginOptions,
  type BrowserPluginArgs,
  type WrapPageElementBrowserArgs,
} from 'gatsby'
import { Provider } from 'react-redux'

import * as Sentry from '@sentry/gatsby'

import { intlManager, getLocaleFromLocation } from 'src/utils/locale'

import { AuthService } from 'src/services/auth'

import { IntlComponent } from 'src/layout/intl'

import { isTesting } from 'src/utils//is-testing'
// import { fetchInject } from 'src/utils/fetch-inject'
import { storage } from 'src/utils/storage'

import { store } from 'src/state/store'

import { DarkModeToggleWrapper } from './src/components/common/dark-mode-toggle-wrapper'
import { OnLoad } from './src/utils/on-load'

import type { Locale } from './src/const/intl'
import type { ReactNode, JSX } from 'react'
import type { PageContext } from './src/types/page-context'

import './src/styles/global.css'

if (
  // eslint-disable-next-line n/prefer-global/process
  process.env['GATSBY_ENV'] === 'production' ||
  // eslint-disable-next-line n/prefer-global/process
  process.env['GATSBY_ENV'] === 'preview'
) {
  Sentry.init({
    dsn: 'https://fc2b48bcf7f3a84d47ad54716103ab44@o334980.ingest.us.sentry.io/4506830076510208',
    integrations: [],
    // eslint-disable-next-line n/prefer-global/process
    environment: process.env['GATSBY_ENV'],
  })
}

function onrejected(err: unknown): void {
  console.error(err instanceof Error ? err.stack : err)
}

function importIntlDeps(): Promise<unknown> {
  const modules = []

  if (typeof Intl.PluralRules === 'undefined') {
    modules.push(
      import('intl-pluralrules')
        .then(() => {
          return import('@formatjs/intl-pluralrules/polyfill')
        })
        .then(() => {
          return Promise.all([
            import('@formatjs/intl-pluralrules/locale-data/en'),
            import('@formatjs/intl-pluralrules/locale-data/ru'),
          ])
        })
    )
  }

  if (typeof Intl.RelativeTimeFormat === 'undefined') {
    modules.push(
      import('@formatjs/intl-relativetimeformat/polyfill').then(() => {
        return Promise.all([
          import('@formatjs/intl-relativetimeformat/locale-data/en'),
          import('@formatjs/intl-relativetimeformat/locale-data/ru'),
        ])
      })
    )
  }

  return Promise.all(modules)
}

function importModules(): Promise<unknown[]> {
  const modules = []

  if (typeof window.IntersectionObserver === 'undefined') {
    modules.push(import('intersection-observer'))
  }

  const intlImport = (
    typeof window.Intl === 'undefined' ? import('intl') : Promise.resolve()
  ).then(importIntlDeps)

  modules.push(intlImport)

  return Promise.all(modules)
}

function initLocale(): Promise<void> {
  return intlManager.init(getLocaleFromLocation(window.location.pathname))
}

function initApp(): void {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    window.document.addEventListener(
      'touchmove',
      (event: TouchEvent): void => {
        if (
          'scale' in event &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          typeof event.scale !== 'undefined' &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          event.scale !== 1
        ) {
          event.preventDefault()
        }
      },
      { passive: false }
    )
  }

  // fetchInject([withPrefix('/fonts/inter.min.css')])
}

export const onClientEntry: GatsbyBrowser['onClientEntry'] =
  function onClientEntry(_args: BrowserPluginArgs, _options: PluginOptions) {
    window.addEventListener('beforeunload', () => {
      const remember = storage.getItem('remember')

      if (remember !== 'true' && !isTesting) {
        AuthService.logout().catch(onrejected)
      }
    })

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', ({ matches }: MediaQueryListEvent): void => {
        console.info('prefers-color-scheme: dark', matches)

        if (matches && storage.getItem('isDarkMode') !== 'true') {
          storage.setItem('isDarkMode', 'true')
        }
      })

    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      storage.setItem('isDarkMode', 'true')
    }

    return importModules().then(initLocale).finally(initApp).catch(onrejected)
  }

interface Props {
  element: ReactNode
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] =
  function wrapRootElement(props: Props): JSX.Element {
    return (
      <Provider store={store}>
        {' '}
        <OnLoad>{props.element}</OnLoad>
      </Provider>
    )
  }

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] =
  function wrapPageElement(
    args: WrapPageElementBrowserArgs<Record<string, unknown>, PageContext>,
    _options: PluginOptions
  ): JSX.Element {
    return (
      <DarkModeToggleWrapper>
        <IntlComponent
          // eslint-disable-next-line total-functions/no-unsafe-type-assertion
          locale={args.props.pageContext['locale'] as Locale}
          template={`browser-wrap ${args.props.location.pathname}`}
        >
          {args.element}
        </IntlComponent>
      </DarkModeToggleWrapper>
    )
  }
