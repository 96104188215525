import {
  memo,
  useEffect,
  // useCallback,
  type ReactNode,
  type ComponentType,
} from 'react'

import { storage } from 'utils/storage'

import { toggleDarkMode } from 'state/reducers/app'
import { useAppDispatch, type ReduxState, useAppSelector } from 'state/store'

// import { logOut } from 'redux/thunks/sign-in'

interface Props {
  children: ReactNode
}

function selector(state: ReduxState): boolean {
  return state.app.isDarkMode
}

function _OnLoad({ children }: Props): ReactNode {
  const isDarkMode = useAppSelector<boolean>(selector)

  const dispatch = useAppDispatch()

  // const cb = useCallback(
  //   (evt: StorageEvent) => {
  //     if (evt.storageArea === localStorage) {
  //       if (evt.key === 'token') {
  //         if (
  //           Boolean(evt.oldValue) &&
  //           (evt.newValue === null || evt.newValue === '')
  //         ) {
  //           dispatch(logOut())
  //         }

  //         if (
  //           (evt.oldValue === null || evt.oldValue === '') &&
  //           Boolean(evt.newValue)
  //         ) {
  //           window.location.reload()
  //         }
  //       }
  //     }
  //   },
  //   [dispatch]
  // )

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      storage.setItem('isDarkMode', 'true')

      if (isDarkMode === false) {
        dispatch(toggleDarkMode())
      }
    }
    // Reset redux auth token when logout from another tab
    // window.addEventListener('storage', cb)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Run only once

  return children
}

export const OnLoad: ComponentType<Props> = memo<Props>(_OnLoad)
