import { HttpService, getApiEndpoint } from 'services/http'

import type { IntlMessageId } from 'const/intl'
import type {
  SignupInput,
  ServerError,
  LoginFailure,
  ServerSuccess,
  ProfileResponse,
  ProfileUpdateInput,
  // NicknameUpdateInput,
  PasswordUpdateInput,
  // NicknameValidateInput,
  // NicknameUpdateResponse,
  LoginEmailTokenResponse,
  // NicknameValidateResponse,
} from 'types/network'

export function isLoginError(
  response: ProfileResponse | ServerError
): response is ServerError {
  return (
    Object.prototype.hasOwnProperty.call(response, 'error') &&
    Object.prototype.hasOwnProperty.call(response, 'intlError')
  )
}

function isServerError<T>(response: T | ServerError): response is ServerError {
  return Object.prototype.hasOwnProperty.call(response, 'error')
}

export class AuthService extends HttpService {
  private static ENDPOINT: string = getApiEndpoint('v1/user')

  private static handleJsonResponse(
    response: Readonly<ServerSuccess | ServerError>
  ): IntlMessageId {
    if (isServerError(response)) {
      throw new Error(response.error)
    }

    if ('message' in response && typeof response.message === 'string') {
      return response.message
    }

    throw new Error('error.unknown')
  }

  public static logout(): Promise<void> {
    return this.post(`${this.ENDPOINT}/logout`, null)
  }

  public static getProfile(): Promise<ProfileResponse> {
    return this.getJson(`${this.ENDPOINT}/profile`)
  }

  public static updateProfile(input: ProfileUpdateInput): Promise<boolean> {
    return this.putJson(`${this.ENDPOINT}/profile`, JSON.stringify(input))
  }

  public static updatePassword(
    input: PasswordUpdateInput
  ): Promise<ServerSuccess | ServerError> {
    return this.postJson(
      `${this.ENDPOINT}/profile/password`,
      JSON.stringify(input)
    )
  }

  // public static updateNickname(
  //   input: NicknameUpdateInput
  // ): Promise<NicknameUpdateResponse | ServerError> {
  //   return this.postJson(
  //     `${this.ENDPOINT}/profile/nickname`,
  //     JSON.stringify(input)
  //   )
  // }

  // public static validateNickname(
  //   input: NicknameValidateInput
  // ): Promise<NicknameValidateResponse> {
  //   return this.postJson<NicknameValidateResponse>(
  //     `${this.ENDPOINT}/profile/nickname`,
  //     JSON.stringify(input)
  //   )
  // }

  public static login(
    email: string,
    password: string,
    remember: boolean
  ): Promise<ProfileResponse | ServerError> {
    return this.postJson<ProfileResponse>(
      `${this.ENDPOINT}/login`,
      JSON.stringify({ email, password, remember })
    ).then<ProfileResponse | ServerError>((response) => {
      // if (isLoginError(response)) {
      //   // eslint-disable-next-line promise/always-return
      //   throw new Error(
      //     response.error !== '' ? response.error : 'error.unknown'
      //   )
      // }

      return response
    })
  }

  public static autoLogin(): Promise<LoginFailure | ProfileResponse> {
    return this.getJson(`${this.ENDPOINT}/login/auto`)
  }

  public static loginByEmailToken(
    token: string
  ): Promise<LoginEmailTokenResponse> {
    return this.getJson(`${this.ENDPOINT}/login/email-token?token=${token}`)
  }

  public static signup(input: Readonly<SignupInput>): Promise<IntlMessageId> {
    return this.postJson<ServerSuccess | ServerError>(
      `${this.ENDPOINT}/register/manual`,
      JSON.stringify(input)
    ).then(this.handleJsonResponse)
  }

  public static forgot(
    email: string,
    locale: string,
    redirect: string
  ): Promise<string> {
    return this.postJson<ServerSuccess | ServerError>(
      `${this.ENDPOINT}/reset?redirect=${redirect}&locale=${locale}`,
      JSON.stringify({ email })
    ).then(this.handleJsonResponse)
  }

  public static reset(password: string, token: string): Promise<string> {
    return this.postJson<ServerSuccess | ServerError>(
      `${this.ENDPOINT}/reset`,
      JSON.stringify({ password, token })
    ).then(this.handleJsonResponse)
  }
}
