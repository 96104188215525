import type { IntlMessageId } from 'const/intl'

import type {
  BaseFields,
  SelectField,
  InputTelField,
  InputTextField,
  InputEmailField,
  FetchSuccessPayload,
} from 'types/fields'

export type FormName = 'contact'

export const formName: FormName = 'contact'

export type EmailField = InputEmailField<FormName, 'email'>
export type PhoneField = InputTelField<FormName, 'phone'>
export type NameField = InputTextField<FormName, 'name'>
export type MessageField = InputTextField<FormName, 'message'>
export type SubjectField = SelectField<
  IntlMessageId | undefined,
  FormName,
  'subject'
>

type ContactFormState = {
  __brand: 'ContactForm'
  formName: FormName
  name: NameField
  email: EmailField
  phone: PhoneField
  message: MessageField
  subject: SubjectField
}

export type ContactForm = BaseFields & ContactFormState

export const initialFields: ContactForm = {
  __brand: 'ContactForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  // Fields
  name: {
    __brand: 'InputTextField',

    path: [formName, 'name'],
    type: 'text',
    isTouched: false,
    valid: false,
    value: '',
  },
  email: {
    __brand: 'InputEmailField',
    path: [formName, 'email'],
    type: 'email',
    isTouched: false,
    valid: false,
    value: '',
  },
  phone: {
    __brand: 'InputTelField',
    path: [formName, 'phone'],
    type: 'tel',
    isTouched: false,
    valid: false,
    value: '',
  },
  subject: {
    __brand: 'SelectField',
    path: [formName, 'subject'],
    isTouched: false,
    valid: false,
    value: undefined,
  },
  message: {
    __brand: 'InputTextField',
    path: [formName, 'message'],
    type: 'text',
    isTouched: false,
    valid: false,
    value: '',
  },
}

export type ContactFieldNames = keyof ContactFormState

// export const initialFieldKeys: Array<ContactFieldNames> = [
//   'name',
//   'email',
//   'phone',
//   'subject',
//   'message',
// ]

export function fetchSuccess(
  draft: ContactForm,
  payload: FetchSuccessPayload
): void {
  Object.assign<ContactForm, Partial<ContactForm>>(draft, {
    isFetching: false,
    errors: [],
    intlErrors: [],
    success: payload.success ?? '',
    intlSuccess: payload.intlSuccess ?? '', // 'contact.success-message'
  })
}

export function reset(draft: ContactForm): void {
  Object.assign<ContactForm, ContactForm>(draft, initialFields)
}
