import type { BaseFields, InputTextField } from 'types/fields'

export type FormName = 'adminDashboardSearch'

export const formName: FormName = 'adminDashboardSearch'

export type SearchField = InputTextField<FormName, 'search'>

type SuperAdminDashboardSearchFormState = {
  __brand: 'SuperAdminDashboardSearchForm'
  formName: FormName
  search: SearchField
}

export type SuperAdminDashboardSearchForm = BaseFields &
  SuperAdminDashboardSearchFormState

export const initialFields: SuperAdminDashboardSearchForm = {
  __brand: 'SuperAdminDashboardSearchForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  // Fields
  search: {
    __brand: 'InputTextField',
    path: [formName, 'search'],
    type: 'text',
    isTouched: false,
    valid: false,
    value: '',
  },
}

export type SuperAdminDashboardSearchFieldNames =
  keyof SuperAdminDashboardSearchFormState
