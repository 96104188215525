import type { Locale } from 'const/intl'

type Environment = 'preview' | 'development' | 'production'

// Used to determine if we are in development or production for analytics, bug tracking, redux dev tools
// eslint-disable-next-line n/prefer-global/process, total-functions/no-unsafe-type-assertion
export const GATSBY_ENV: Environment = (process.env['GATSBY_ENV'] ??
  'production') as Environment

// Used to render default locale without prefix and for for navigation.
// eslint-disable-next-line n/prefer-global/process, total-functions/no-unsafe-type-assertion
export const GATSBY_DEFAULT_LOCALE: Locale = (process.env[
  'GATSBY_DEFAULT_LOCALE'
] ?? 'en') as unknown as Locale

export const GATSBY_SITE_URL: string =
  // eslint-disable-next-line n/prefer-global/process
  process.env['GATSBY_SITE_URL'] ?? 'undefined'

// eslint-disable-next-line n/prefer-global/process
export const GATSBY_API_URL: string = process.env['GATSBY_API_URL'] ?? ''
// eslint-disable-next-line n/prefer-global/process
export const GATSBY_ADMIN_URL: string = process.env['GATSBY_ADMIN_URL'] ?? ''
// eslint-disable-next-line n/prefer-global/process
export const GATSBY_AUTH_URL: string = process.env['GATSBY_AUTH_URL'] ?? ''

// export const WORKER_PROFILES_URL: string =
// // eslint-disable-next-line n/prefer-global/process
// process.env['WORKER_LOGIN_URL'] ?? 'https://worker-profiles-router.justfly1984.workers.dev'

// export const WORKER_ADMIN_SEARCH_URL: string =
// // eslint-disable-next-line n/prefer-global/process
// process.env['WORKER_ADMIN_SEARCH_URL'] ?? 'https://worker-profiles-router.justfly1984.workers.dev'

// export const WORKER_ADMIN_USER_URL: string =
// // eslint-disable-next-line n/prefer-global/process
// process.env['WORKER_ADMIN_USER_URL'] ?? 'https://worker-profiles-router.justfly1984.workers.dev'
