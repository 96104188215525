import { storage } from 'utils/storage'

import type {
  BaseFields,
  InputEmailField,
  InputPasswordField,
  InputCheckboxField,
  FetchSuccessPayload,
} from 'types/fields'

export type FormName = 'logIn'

export const formName: FormName = 'logIn'

export type EmailField = InputEmailField<FormName, 'email'>
export type PasswordField = InputPasswordField<FormName, 'password'>
export type RememberField = InputCheckboxField<FormName, 'remember'>

type LoginFormState = {
  __brand: 'LoginForm'
  formName: FormName
  email: EmailField
  password: PasswordField
  remember: RememberField
}

export type LogInForm = BaseFields & LoginFormState

export const initialFields: LogInForm = {
  __brand: 'LoginForm',
  formName,
  // Request
  isFetching: false, // Do not set to `false` ^^^^
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  // Fields
  email: {
    __brand: 'InputEmailField',

    path: [formName, 'email'],
    type: 'email',
    isTouched: false,
    valid: false,
    value: storage.getItem('logIn') ?? '',
  },
  password: {
    __brand: 'InputPasswordField',
    path: [formName, 'password'],
    type: 'password',
    isTouched: false,
    valid: false,
    value: '',
  },
  remember: {
    __brand: 'InputCheckboxField',
    path: [formName, 'remember'],
    checked: storage.getItem('remember') === 'true',
    isTouched: false,
    valid: false,
  },
}

export type LogInFieldNames = keyof LoginFormState

// export const initialFieldKeys: Array<LogInFieldNames> = [
//   'email',
//   'password',
//   'remember',
// ]

export function fetchSuccess(
  draft: LogInForm,
  { success, intlSuccess }: FetchSuccessPayload
): void {
  Object.assign<LogInForm, Partial<LogInForm>>(draft, {
    isFetching: false,
    errors: [],
    intlErrors: [],
    success: success ?? '',
    intlSuccess: intlSuccess ?? '',
  })
}

export function reset(draft: LogInForm): void {
  Object.assign<LogInForm, LogInForm>(draft, initialFields)
}
