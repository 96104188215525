import { configureStore } from '@reduxjs/toolkit'

import { reducer } from 'state/reducers'
import {
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  useDispatch,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  useSelector,
  type TypedUseSelectorHook,
} from 'react-redux'
import { GATSBY_ENV } from '../env'

declare global {
  interface Document {
    msHidden: boolean
    webkitHidden: boolean
  }
}

export const store = configureStore({
  reducer,
  devTools: GATSBY_ENV === 'development',
})

type AppDispatch = typeof store.dispatch

// type GetState = typeof store.getState

export type ReduxState = ReturnType<typeof store.getState>
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch<AppDispatch>
export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector
