class CustomStorage {
  private values: Map<string, string>

  public constructor() {
    this.values = new Map()
  }

  public key(index: number): string | undefined {
    if (index < 0) {
      return undefined
    }

    const keys = [...this.values.keys()]

    return keys.length > index ? keys[index] : undefined
  }

  public setItem<T extends { toString(): string }>(
    key: string,
    value: T
  ): void {
    this.values.set(key, value.toString())
  }

  public getItem(key: string): string | null {
    return this.values.get(key) ?? null
  }

  public removeItem(key: string): void {
    this.values.delete(key)
  }

  public clear(): void {
    this.values.clear()
  }
}

export const storage =
  typeof window !== 'undefined' && typeof window.localStorage === 'object'
    ? window.localStorage
    : new CustomStorage()
