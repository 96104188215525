import { createSlice } from '@reduxjs/toolkit'

import type { Flags } from 'const/iso2'
import type { LoginPayload, UserRoles } from 'types/network'

export interface ProfileState {
  role: UserRoles
  isLoggedIn: boolean
  isVerified: boolean
  isComplete: boolean
  country: Flags | ''
  birthday: number
  firstName: string
  lastName: string
  email: string
  phone: string
  token: string
  isGithubAccountSynced: boolean
}

const initialState: Readonly<ProfileState> = {
  role: 'anonymous',
  isLoggedIn: false,
  isVerified: false,
  isComplete: true,
  country: '',
  firstName: '',
  lastName: '',
  birthday: -Infinity,
  email: '',
  phone: '',
  token: '',
  isGithubAccountSynced: false,
} as const

function update(
  draft: ProfileState,
  {
    role,
    firstName,
    lastName,
    country,
    // email,
    // remember,
    phone,
    birthday,
    isGithubAccountSynced,
  }: Partial<LoginPayload>
): void {
  if (typeof role === 'string' && role !== draft.role) {
    draft.role = role
  }

  if (typeof isGithubAccountSynced === 'boolean') {
    draft.isGithubAccountSynced = isGithubAccountSynced
  }

  if (typeof firstName !== 'undefined') {
    draft.firstName = firstName
  }

  if (typeof lastName !== 'undefined') {
    draft.lastName = lastName
  }

  if (country !== undefined) {
    draft.country = country
  }

  if (birthday != null) {
    draft.birthday = birthday
  }

  if (typeof phone === 'string' && phone !== '') {
    draft.phone = phone
  }

  draft.isComplete = Boolean(
    draft.firstName &&
      draft.lastName &&
      draft.phone &&
      draft.birthday &&
      draft.country &&
      draft.isGithubAccountSynced
  )
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    logOut(state): void {
      Object.assign<Readonly<ProfileState>, Readonly<ProfileState>>(
        state,
        initialState
      )
    },
    logIn(state, action: { payload: LoginPayload }): void {
      state.role = action.payload.role

      state.isLoggedIn = true
      // state.isVerified = isVerified !== undefined ? isVerified : true

      if (typeof action.payload.email !== 'undefined') {
        state.email = action.payload.email
      }

      if (typeof action.payload.token !== 'undefined') {
        state.token = action.payload.token
      }

      update(state, action.payload)
    },
    updateProfile(state, action: { payload: Partial<LoginPayload> }): void {
      update(state, action.payload)
    },
    profileSetComplete(state, action: { payload: boolean }): void {
      state.isComplete = action.payload
    },
    reset(state) {
      Object.assign<ProfileState, ProfileState>(state, initialState)
    },
  },
})

export const { logIn, logOut, updateProfile, profileSetComplete } =
  profileSlice.actions

export const reducer = profileSlice.reducer
