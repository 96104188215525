import { createSlice } from '@reduxjs/toolkit'

import { storage } from 'utils/storage'

export interface ApplicationState {
  isMobileMenuOpen: boolean
  isInitialized: boolean
  isDarkMode: boolean
}

const initialState: Readonly<ApplicationState> = {
  isMobileMenuOpen: false,
  isInitialized: false,
  isDarkMode: storage.getItem('isDarkMode') === 'true',
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleMobileMenu(state): void {
      state.isMobileMenuOpen = !state.isMobileMenuOpen
    },
    toggleDarkMode(state): void {
      state.isDarkMode = !state.isDarkMode
    },
  },
})

export const { toggleMobileMenu, toggleDarkMode } = appSlice.actions

export const reducer = appSlice.reducer
