import type { BaseFields, InputTextField } from 'types/fields'

export type FormName = 'adminDashboardSearch'

export const formName: FormName = 'adminDashboardSearch'

export type SearchField = InputTextField<FormName, 'search'>

type AdminDashboardSearchFormState = {
  __brand: 'AdminDashboardSearchForm'
  formName: FormName
  search: SearchField
}

export type AdminDashboardSearchForm = BaseFields &
  AdminDashboardSearchFormState

export const initialFields: AdminDashboardSearchForm = {
  __brand: 'AdminDashboardSearchForm',
  formName,
  // Request
  isFetching: false,
  success: '',
  intlSuccess: '',
  errors: [],
  intlErrors: [],
  // Fields
  search: {
    __brand: 'InputTextField',
    path: [formName, 'search'],
    type: 'text',
    isTouched: false,
    valid: false,
    value: '',
  },
}

export type AdminDashboardSearchFieldNames = keyof AdminDashboardSearchFormState
