import {
  memo,
  useMemo,
  useState,
  type JSX,
  useEffect,
  type ReactNode,
  type ComponentType,
} from 'react'
import { IntlProvider } from 'react-intl'
// import { useLocation } from '@gatsbyjs/reach-router'
import { shallowEqual } from 'react-redux'

import { intlManager } from 'utils/locale'

import { setLocale } from 'state/reducers/intl'

import type { ReduxState } from 'state/reducers'
import type { Locale, IntlMessageId } from 'const/intl'
import { useAppDispatch, useAppSelector } from 'state/store'

interface SelectorProps {
  locale: Locale
  isInitialized: boolean
}

function selector({
  intl: { locale, isInitialized },
}: ReduxState): SelectorProps {
  return {
    locale,
    isInitialized,
  }
}

interface Props {
  locale: Locale
  children: ReactNode
  template: string
}

function _IntlComponent({
  locale: staticLocale,
  children,
  template,
}: Props): JSX.Element {
  const state = useAppSelector<SelectorProps>(selector, shallowEqual)

  const { locale, isInitialized } = useMemo<SelectorProps>(() => {
    if (!state.isInitialized) {
      return {
        isInitialized: state.isInitialized,
        locale: staticLocale,
      }
    }

    return {
      isInitialized: state.isInitialized,
      locale: state.locale,
    }
  }, [state.isInitialized, state.locale, staticLocale])

  const [currentLocale, setCurrent] = useState<Locale>(locale)

  const dispatch = useAppDispatch()

  useEffect((): void => {
    if (!isInitialized) {
      if (currentLocale === staticLocale) {
        dispatch(setLocale({ locale: staticLocale }))
      } else {
        dispatch(setLocale({ locale: currentLocale }))
      }

      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentLocale,
    dispatch,
    // isInitialized,
    staticLocale,
  ])

  useEffect((): (() => void) => {
    function listener(loc: Locale): void {
      setCurrent(loc)
    }

    return intlManager.subscribe(listener)
  }, [])

  const messages = useMemo<
    Readonly<Record<IntlMessageId | string, string>>
  >(() => {
    return intlManager.getMessages(currentLocale, template)
  }, [currentLocale, template])

  // console.info('template: ', template)
  // console.info('locale: ', locale)
  // console.info('test message: ', messages['country.jp'])

  return (
    <IntlProvider locale={currentLocale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export const IntlComponent: ComponentType<Props> = memo<Props>(_IntlComponent)
