import { createSlice } from '@reduxjs/toolkit'

import type { User } from 'types/network'

export interface AdminDashboardState {
  isFetching: boolean
  users: User[]
  currentPage: number
  totalPages: number
  totalUsers: number
}

const initialState: Readonly<AdminDashboardState> = {
  isFetching: false,
  users: [],
  currentPage: 0,
  totalPages: 0,
  totalUsers: 0,
}

const appSlice = createSlice({
  name: 'admin-dashboard',
  initialState,
  reducers: {
    fetchRequest(state): void {
      state.isFetching = true
    },
    fetchSuccess(
      state,
      action: {
        payload: {
          users: User[]
          currentPage: number
          totalPages: number
          totalUsers: number
        }
      }
    ): void {
      state.isFetching = false
      state.users = action.payload.users
      state.currentPage = action.payload.currentPage
      state.totalPages = action.payload.totalPages
      state.totalUsers = action.payload.totalUsers
    },
    fetchFailure(state): void {
      state.isFetching = false
      state.users = []
      state.currentPage = 0
      state.totalPages = 0
    },
    setCurrentPage(state, action: { payload: number }): void {
      state.currentPage = action.payload
    },
    reset(state) {
      Object.assign<AdminDashboardState, AdminDashboardState>(
        state,
        initialState
      )
    },
  },
})

export const {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  setCurrentPage,
  reset,
} = appSlice.actions

export const reducer = appSlice.reducer
