exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-admin-dashboard-tsx": () => import("./../../../src/templates/admin-dashboard.tsx" /* webpackChunkName: "component---src-templates-admin-dashboard-tsx" */),
  "component---src-templates-admin-user-tsx": () => import("./../../../src/templates/admin-user.tsx" /* webpackChunkName: "component---src-templates-admin-user-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-customers-tsx": () => import("./../../../src/templates/customers.tsx" /* webpackChunkName: "component---src-templates-customers-tsx" */),
  "component---src-templates-dashboard-tsx": () => import("./../../../src/templates/dashboard.tsx" /* webpackChunkName: "component---src-templates-dashboard-tsx" */),
  "component---src-templates-developers-tsx": () => import("./../../../src/templates/developers.tsx" /* webpackChunkName: "component---src-templates-developers-tsx" */),
  "component---src-templates-forgot-tsx": () => import("./../../../src/templates/forgot.tsx" /* webpackChunkName: "component---src-templates-forgot-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-log-in-tsx": () => import("./../../../src/templates/log-in.tsx" /* webpackChunkName: "component---src-templates-log-in-tsx" */),
  "component---src-templates-log-in-with-email-tsx": () => import("./../../../src/templates/log-in-with-email.tsx" /* webpackChunkName: "component---src-templates-log-in-with-email-tsx" */),
  "component---src-templates-maintainers-tsx": () => import("./../../../src/templates/maintainers.tsx" /* webpackChunkName: "component---src-templates-maintainers-tsx" */),
  "component---src-templates-packages-tsx": () => import("./../../../src/templates/packages.tsx" /* webpackChunkName: "component---src-templates-packages-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../../../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-profile-tsx": () => import("./../../../src/templates/profile.tsx" /* webpackChunkName: "component---src-templates-profile-tsx" */),
  "component---src-templates-reset-tsx": () => import("./../../../src/templates/reset.tsx" /* webpackChunkName: "component---src-templates-reset-tsx" */),
  "component---src-templates-roadmap-tsx": () => import("./../../../src/templates/roadmap.tsx" /* webpackChunkName: "component---src-templates-roadmap-tsx" */),
  "component---src-templates-sign-up-tsx": () => import("./../../../src/templates/sign-up.tsx" /* webpackChunkName: "component---src-templates-sign-up-tsx" */),
  "component---src-templates-sign-up-with-email-tsx": () => import("./../../../src/templates/sign-up-with-email.tsx" /* webpackChunkName: "component---src-templates-sign-up-with-email-tsx" */),
  "component---src-templates-sign-up-with-github-tsx": () => import("./../../../src/templates/sign-up-with-github.tsx" /* webpackChunkName: "component---src-templates-sign-up-with-github-tsx" */),
  "component---src-templates-signup-success-tsx": () => import("./../../../src/templates/signup-success.tsx" /* webpackChunkName: "component---src-templates-signup-success-tsx" */),
  "component---src-templates-superadmin-admin-tsx": () => import("./../../../src/templates/superadmin-admin.tsx" /* webpackChunkName: "component---src-templates-superadmin-admin-tsx" */),
  "component---src-templates-superadmin-dashboard-tsx": () => import("./../../../src/templates/superadmin-dashboard.tsx" /* webpackChunkName: "component---src-templates-superadmin-dashboard-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */),
  "component---src-templates-terms-tsx": () => import("./../../../src/templates/terms.tsx" /* webpackChunkName: "component---src-templates-terms-tsx" */),
  "component---src-templates-ui-kit-tsx": () => import("./../../../src/templates/ui-kit.tsx" /* webpackChunkName: "component---src-templates-ui-kit-tsx" */),
  "component---src-templates-unverified-tsx": () => import("./../../../src/templates/unverified.tsx" /* webpackChunkName: "component---src-templates-unverified-tsx" */),
  "component---src-templates-verify-failure-tsx": () => import("./../../../src/templates/verify-failure.tsx" /* webpackChunkName: "component---src-templates-verify-failure-tsx" */),
  "component---src-templates-verify-success-tsx": () => import("./../../../src/templates/verify-success.tsx" /* webpackChunkName: "component---src-templates-verify-success-tsx" */)
}

